var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',[_c('h2',{staticClass:"page-heading"},[_vm._v(_vm._s(_vm.$t("products.headings.production")))]),_c('v-row',{staticClass:"page-section"},[_c('v-col',{staticClass:"pr-md-10",attrs:{"cols":"12","md":"6"}},[_c('v-label',[_vm._v(_vm._s(_vm.$t("labels.productionYear"))),_c('req')],1),_c('v-text-field',{directives:[{name:"mask",rawName:"v-mask",value:('####'),expression:"'####'"}],attrs:{"rules":[_vm.rule.required, _vm.rule.year]},model:{value:(_vm.value.production_info.production_year),callback:function ($$v) {_vm.$set(_vm.value.production_info, "production_year", $$v)},expression:"value.production_info.production_year"}}),_c('v-label',[_vm._v(_vm._s(_vm.$t("labels.releaseYear"))),_c('req')],1),_c('v-text-field',{directives:[{name:"mask",rawName:"v-mask",value:('####'),expression:"'####'"}],attrs:{"rules":[_vm.rule.required, _vm.rule.year]},model:{value:(_vm.value.production_info.release_year),callback:function ($$v) {_vm.$set(_vm.value.production_info, "release_year", $$v)},expression:"value.production_info.release_year"}})],1),_c('v-col',{staticClass:"pl-md-10",attrs:{"cols":"12","md":"6"}},[_c('v-label',[_vm._v(_vm._s(_vm.$t("labels.countryOrigin"))),_c('req')],1),_c('country-picker',{attrs:{"attrs":{
          rules: [_vm.rule.required],
        }},model:{value:(_vm.value.production_info.country_of_origin),callback:function ($$v) {_vm.$set(_vm.value.production_info, "country_of_origin", $$v)},expression:"value.production_info.country_of_origin"}}),_c('v-label',[_vm._v(_vm._s(_vm.$t("labels.productionStatus"))),_c('req')],1),_c('v-radio-group',{attrs:{"rules":[_vm.rule.required]},model:{value:(_vm.value.production_info.production_status),callback:function ($$v) {_vm.$set(_vm.value.production_info, "production_status", $$v)},expression:"value.production_info.production_status"}},[_c('v-radio',{attrs:{"label":_vm.$t('labels.released'),"value":"released"}}),_c('v-radio',{attrs:{"label":_vm.$t('labels.unreleased'),"value":"unreleased"}})],1)],1)],1),_c('v-row',{staticClass:"page-section"},[_c('v-col',{staticClass:"pr-md-10",attrs:{"cols":"12","md":"6"}},[_c('v-label',[_vm._v(_vm._s(_vm.$t("labels.director")))]),_c('multiple-person-field',{attrs:{"attrs":{
          rules: [_vm.rule.required],
        }},model:{value:(_vm.value.production_info.directors),callback:function ($$v) {_vm.$set(_vm.value.production_info, "directors", $$v)},expression:"value.production_info.directors"}}),_c('v-label',[_vm._v(_vm._s(_vm.$t("labels.producer")))]),_c('multiple-person-field',{attrs:{"attrs":{
          rules: [_vm.rule.required],
        }},model:{value:(_vm.value.production_info.producers),callback:function ($$v) {_vm.$set(_vm.value.production_info, "producers", $$v)},expression:"value.production_info.producers"}}),_c('v-label',[_vm._v(_vm._s(_vm.$t("labels.awards")))]),_c('multiple-text-field',{attrs:{"attrs":{
          rules: [_vm.rule.required],
        }},model:{value:(_vm.value.production_info.awards),callback:function ($$v) {_vm.$set(_vm.value.production_info, "awards", $$v)},expression:"value.production_info.awards"}})],1),_c('v-col',{staticClass:"pl-md-10",attrs:{"cols":"12","md":"6"}},[_c('v-label',[_vm._v(_vm._s(_vm.$t("labels.writer")))]),_c('multiple-person-field',{attrs:{"attrs":{
          rules: [_vm.rule.required],
        }},model:{value:(_vm.value.production_info.writers),callback:function ($$v) {_vm.$set(_vm.value.production_info, "writers", $$v)},expression:"value.production_info.writers"}}),_c('v-label',[_vm._v(_vm._s(_vm.$t("labels.cast")))]),_c('multiple-person-field',{attrs:{"attrs":{
          rules: [_vm.rule.required],
        }},model:{value:(_vm.value.production_info.cast),callback:function ($$v) {_vm.$set(_vm.value.production_info, "cast", $$v)},expression:"value.production_info.cast"}}),_c('v-label',[_vm._v(_vm._s(_vm.$t("labels.festivals")))]),_c('multiple-text-field',{attrs:{"attrs":{
          rules: [_vm.rule.required],
        }},model:{value:(_vm.value.production_info.festivals),callback:function ($$v) {_vm.$set(_vm.value.production_info, "festivals", $$v)},expression:"value.production_info.festivals"}})],1)],1),_c('v-row',{staticClass:"page-section"},[_c('v-col',{staticClass:"pr-md-10",attrs:{"cols":"12","md":"6"}},[_c('v-label',[_vm._v(_vm._s(_vm.$t("labels.boxOffice")))]),_c('v-text-field',{attrs:{"placeholder":_vm.$t('labels.gboGlobal')},model:{value:(_vm.value.production_info.box_office.globalGbo),callback:function ($$v) {_vm.$set(_vm.value.production_info.box_office, "globalGbo", $$v)},expression:"value.production_info.box_office.globalGbo"}}),_c('v-text-field',{attrs:{"placeholder":_vm.$t('labels.gboDomestic')},model:{value:(_vm.value.production_info.box_office.domesticGbo),callback:function ($$v) {_vm.$set(_vm.value.production_info.box_office, "domesticGbo", $$v)},expression:"value.production_info.box_office.domesticGbo"}}),_c('v-text-field',{attrs:{"placeholder":_vm.$t('labels.gboInternational')},model:{value:(_vm.value.production_info.box_office.internationalGbo),callback:function ($$v) {_vm.$set(_vm.value.production_info.box_office, "internationalGbo", $$v)},expression:"value.production_info.box_office.internationalGbo"}})],1),_c('v-col',{staticClass:"pl-md-10",attrs:{"cols":"12","md":"6"}})],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }